<div id="bot" style="background-color: #1A1A1A;">
    <div class="container flex-center p-0">
        <p class="my-5 flex-line">
            <a class="link-svg mx-3" href="https://www.instagram.com/lvperes" target="_blank" title="">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10.7426 4C7.01878 4 4 7.01878 4 10.7426V21.2574C4 24.9812 7.01878 28 10.7426 28H21.2574C24.9812 28 28 24.9812 28 21.2574V10.7426C28 7.01878 24.9812 4 21.2574 4H10.7426ZM10.6484 6.21618C8.20056 6.21618 6.21618 8.20056 6.21618 10.6484V21.3517C6.21618 23.7996 8.20056 25.784 10.6484 25.784H21.3046C23.7524 25.784 25.7368 23.7996 25.7368 21.3517V10.6484C25.7368 8.20056 23.7524 6.21618 21.3046 6.21618H10.6484Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M15.9999 22.2003C19.3983 22.2003 22.1532 19.4454 22.1532 16.047C22.1532 12.6487 19.3983 9.8938 15.9999 9.8938C12.6016 9.8938 9.84668 12.6487 9.84668 16.047C9.84668 19.4454 12.6016 22.2003 15.9999 22.2003ZM15.9998 20.0315C18.2263 20.0315 20.0313 18.2266 20.0313 16.0001C20.0313 13.7736 18.2263 11.9686 15.9998 11.9686C13.7734 11.9686 11.9684 13.7736 11.9684 16.0001C11.9684 18.2266 13.7734 20.0315 15.9998 20.0315Z"
                        fill="white" />
                    <circle cx="22.4596" cy="9.58747" r="1.43811" fill="white" />
                </svg>
            </a>


            <a class="link-svg mx-3" href="https://github.com/lieira/" target="_blank" title="">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.6087 12.3459C6.63726 16.5555 8.51217 19.1911 13.6003 19.7036C13.0239 20.2089 12.8178 20.6883 12.5753 21.7534C12.0591 22.0129 11.743 22.1336 10.9281 22.1195C9.7228 22.0742 8.76841 21.5338 8.36574 20.7651C7.85839 19.7191 7.35751 19.2724 5.80339 18.8982C5.37458 18.8278 5.14451 18.8616 5.03469 19.0446C4.92551 19.2266 5.09508 19.3782 5.25159 19.5181L5.25432 19.5205C5.76761 19.9793 6.53549 20.1428 7.15777 21.4606C8.03471 23.4658 8.95976 24.0519 11.0379 24.0595C11.5138 24.0595 11.9756 24.0465 12.5753 23.8765V27.6102C15.5795 28.2095 17.5266 28.0797 20.299 27.4804V22.3391C20.2557 21.1579 20.0807 20.5633 19.3106 19.7036C24.7282 19.0812 26.2035 16.3724 26.2656 12.3459C26.2991 10.1723 25.8402 9.32036 24.7282 8.02655C25.2444 6.34934 25.1442 5.47712 24.5086 4C23.0078 4 21.9394 4.34471 20.3356 5.57402C19.1044 5.20769 18.2756 5.09588 16.5287 5.06155C14.8605 4.98792 14.0182 5.13089 12.6119 5.57402C11.0166 4.41335 9.72014 3.99996 8.36574 4C7.79826 5.45524 7.72388 6.32713 8.21932 8.02655C6.96277 9.3832 6.59497 10.3235 6.6087 12.3459Z"
                        fill="white" />
                </svg>
            </a>

        </p>

        <p>
            <!-- <img width="100px" src="assets/img/logo.png"> -->
        </p>
        <p style=" color: #888; text-align: center; font-size: 16px;">
            Leonardo Vieira Peres &copy; Todos os direitos reservados - 2021
        </p>
    </div>
</div>