<nav class="navbar fixed-top navbar-expand-lg navbar-dark" style="background-color: #1a1a1a; padding: 0px 30px;">
    <!-- <a class="navbar-brand" href="?pg=home"></a> -->
    <a class="navbar-brand" href="javascript:void(0)">
        <!-- <img src="assets/img/logo.png" width="50px"> -->

    </a>
    <button class="navbar-toggler" style="margin: 0; padding: 0; border: 0px;" type="button" data-toggle="collapse" data-target="#conteudoNavbarSuportado" aria-controls="conteudoNavbarSuportado" aria-expanded="false" aria-label="Alterna navegação">
        <!--<span class="navbar-toggler-icon"></span> -->
        <input id="hamburguer" type="checkbox" style="display: none;">
        <label for="hamburguer" style="margin: 0; padding: 0; display:block">
            <div class="hamburguer"></div>
        </label>
    </button>

    <div class="collapse navbar-collapse" id="conteudoNavbarSuportado">
        <ul class="navbar-nav mr-auto justify-content-center">

            <li class="nav-item">
                <a class="menu-link page-scroll" href="#home" title="Página Inicial Leonardo Vieira Peres">Home</a>
            </li>

            <li class="nav-item">
                <a class="menu-link page-scroll" href="#about" title="Quem é Leonardo Vieira Peres?">Quem sou</a>
            </li>

            <li class="nav-item">
                <a class="menu-link" href="https://www.instagram.com/lvperes" target="_blank" title="Solicitar Orçamento Leonardo Vieira Peres">Solicitar Orçamento</a>
            </li>

        </ul>
        <section class="py-3" style="display: flex; align-items: center;">
            


            <!--<input class="form-control mr-sm-2"  style="width: 100px;" type="search" placeholder="Pesquisar" aria-label="Pesquisar">
                <button class="btn btn-outline-danger my-2 my-sm-0" type="submit">Pesquisar</button> -->
        </section>
    </div>
</nav>